
import { Component, Prop, Vue } from "vue-property-decorator";
import store from "@/store";
import { rateUser } from "@/serviceClients/userWebServices";
import { UserRatingInfo } from "./assistantsWebServices";
import { pushError } from "../core";
import './RatingStars.scss'

@Component
export default class RatingStars extends Vue {
  @Prop() ratingInfo!: UserRatingInfo;
  @Prop({ required: false, default: true }) ratingEnable!: boolean;
  userId!: string;
  ratingFormValue = 0;

  ratingEdited() {
    rateUser(this.userId, this.ratingFormValue)
      .then(() => this.$emit("onRated"))
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      });
  }

  get averageRating() {
    return this.ratingInfo.averageRating.toFixed(1);
  }

  get totalRatings() {
    return this.ratingInfo.totalRatings;
  }

  mounted() {
    this.ratingFormValue = this.ratingInfo?.givenRatingByUser ?? 0;
  }

  get canBeRated() {
    if (!this.ratingEnable) {
      return false;
    }

    const loggedUser = store.getters.module.user;
    this.userId = this.$route.params.id;
    return (
      !!loggedUser &&
      loggedUser.id !== this.userId &&
      this.ratingInfo?.canBeRated
    );
  }
}
