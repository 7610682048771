
import Vue from "vue";
import Component from "vue-class-component";
import AssistantDetails from "@/assistants/Details.vue";

@Component({
  components: {
    AssistantDetails,
  },
})
export default class Assistant extends Vue {}
