
import Vue from "vue";
import Component from "vue-class-component";
import AssistantDetails from "@/assistants/Details.vue";
import Constants from "@/Constants";

@Component({
  components: {
    AssistantDetails,
  },
})
export default class Assistant extends Vue {
  get SupportUserId() { return Constants.SupportUserId };
}
