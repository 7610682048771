import { getLanguage } from "@/core/translate"

export type HeightScale = "inches" | "cm" 

export const heightScaleOptions: { text: string, value: HeightScale }[] = [
  { text: "inches", value: "inches" },
  { text: "cm", value: "cm" },
]

const scaleRatios = {
    'cm': 1,
    'inches': 2.54,
}

const regionScales: { [scale: string]: string[] } = {
    'cm': [ 'pl', 'uk', 'ru', 'ja', 'ro' ],
    'inches': [ 'en' ],
}

export const getHeightInOtherScale = (height: number, prevScale: HeightScale, newScale: HeightScale): number => {
    return height * scaleRatios[prevScale] / scaleRatios[newScale]
}

export const displayHeight = (height: number | undefined) => {
    if(!!height) {
        for (var scale of Object.keys(regionScales)) {
            if (regionScales[scale].includes(getLanguage())) {
                return `${Math.round(getHeightInOtherScale(height, 'cm', scale as HeightScale))} ${scale}`
            }
        }
    }
}
